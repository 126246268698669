<template>
  <div class="prayer-time" v-if="model">
    <div class="prayer-background">
      <img src="/images/prayer/bg_prayer.png" />
    </div>
    <div class="prayer">
      <div class="text">
        <span>
          <strong>{{ model.name }} Ezanı</strong>
          <br />Vakti
        </span>
        <span class="time">{{ model.time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store/index';
export default {
  name: 'prayer-time',
  props: ['data'],
  data() {
    return {
      destroyed: false
    };
  },
  created() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch('setNextPlaylist');
      }, 60000);
    }
  },
  computed: {
    model() {
      return this.$store.state.prayerTime;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
.prayer-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
  }
}

.prayer {
  text-align: center;
  display: inline-block;
  position: absolute;
  top: calc(50% - (710px / 2));
  left: 0;
  right: 0;
  width: 1141px;
  height: 711px;
  background: url('/images/prayer/prayer-border.png') center no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  font-family: Montserrat-Bold;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 128px 0;
  .text {
    display: inline-block;
    width: auto;
    span {
      font-size: 104px;
      display: block;
      line-height: 101px;
      display: block;
      text-align: right;
      font-family: Montserrat-Light;
      strong {
        font-size: 94px;
        font-family: Montserrat-Bold;
      }
    }
    .time {
      font-family: Montserrat-Bold;
      text-align: center;
      font-size: 225px;
      line-height: 221px;
    }
  }
}

.time {
  -webkit-animation-name: x-opa;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}
@keyframes x-opa {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
